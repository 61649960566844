<!--评论-->
<template>
<div class="box">
    <div class="register">
        <ul class="register-ul">
            <li class="register-li">
                <div class="register-li-item">
                    <span class="input-cmt-sn"> 1</span>
                    <a-input
                     class="input-cmt"
                     v-model="commentIds[0]" 
                     id="comment1" 
                     name="comment1" 
                     :title="$t('energy.enedata126')" 
                     :placeholder="$t('energy.enedata126')"
                     :maxLength="maxLength">
                     </a-input>
                </div>
            </li>
            <li class="register-li">
                <div class="register-li-item">
                    <span class="input-cmt-sn"> 2</span>
                    <a-input class="input-cmt" v-model="commentIds[1]" id="comment2" name="comment2" :title="$t('energy.enedata126')" :placeholder="$t('energy.enedata126')" :maxLength="maxLength"></a-input>
                </div>
            </li>
            <li class="register-li">
                <div class="register-li-item">
                    <span class="input-cmt-sn"> 3</span>
                    <a-input class="input-cmt" v-model="commentIds[2]" id="comment3" name="comment3"  :title="$t('energy.enedata126')" :placeholder="$t('energy.enedata126')" :maxLength="maxLength"></a-input>
                </div>
            </li>
        </ul>
        <ul class="register-ul">
            <li class="register-li">
                <div class="register-li-item">
                    <span class="input-cmt-sn"> 4</span>
                <a-input class="input-cmt" v-model="commentIds[3]" id="comment4" name="comment4"  :title="$t('energy.enedata126')" :placeholder="$t('energy.enedata126')" :maxLength="maxLength"></a-input>
                </div>
            </li>
            <li class="register-li">
                <div class="register-li-item">
                    <span class="input-cmt-sn"> 5</span>
                    <a-input class="input-cmt" v-model="commentIds[4]" id="comment5" name="comment5"  :title="$t('energy.enedata126')" :placeholder="$t('energy.enedata126')" :maxLength="maxLength"></a-input>
                </div> 
            </li>
            <li class="register-li">
                <!-- 最多5条评论 -->
            <p class="register-li-item-p">{{$t('energy.enedata125')}}</p>
            <!-- 清空 -->
            <a-button type="primary" ghost @click="clearComment">{{$t('energy.enedata127')}}</a-button>
            </li>
        </ul>
    </div>
 </div>   
</template>

<script>
export default {
  props: {
    commentIds: {
      type:Array, 
      default:function () {
        return [ "","","","",""]
      },
    }
  },
  data() {
    return {      
      maxLength: 50,
    }    
  },
  methods: {
    clearComment(){
        console.log("clearComment");
        this.$confirm({
            // title: "确定要清除所有评论内容吗?",
            title:this.$t("energy.enedata140"),
            centered: true,
            onOk: () => {
                this.commentIds = ["","","","",""];    
        }
        });     
    },
  },
};
</script>

<style scoped>
.box{
    border-radius: 3px;
    border: 1px solid #dedfdf;
    margin-bottom: 20px;
}
.register {
  display: flex;
  margin-bottom: 15px;
}
.register-ul {
  width: 50%;
  padding: 0 10px;
  margin: 0;
}
.register-li {
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  border-bottom: 1px solid #dfe0e0;
  white-space: nowrap;
  overflow: hidden;
}
.register-li:last-child{
    border-bottom: 0;
}
.register-li-item {
  display: flex;
}
.register-li-item-p {
  padding: 0px 10px;
  margin: 0;
}
.register-li-img {
  width: 40px;
  height: 40px;
}
.input-width {
  width: 120px;
  margin-right: 15px;
}
.input-cmt{width:300px;border:none;}
.input-cmt-sn{
    height:32px;line-height:32px;margin-right:10px;
}
</style>