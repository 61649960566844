<template>
<!-- 表格顶部标题 -->
  <div id="scheduleCtrl">
    <div class="tabletop">
      <span>{{name}}</span>
      <span class="switch" @click.stop>
            <a-switch
              :value="changeState"
              :default-checked="state"
              :loading="loading"
              :disabled="disabled"
              @change="onChange"
            />
          </span>
    </div>
  </div>
</template>

<script>
export default {
    props:{
      changeState: Boolean,
    },    
    data() {
      return {
        loading: false,   
        disabled: true, //禁止开关动作
        name: this.$t('lightDetails.a13'),
      }
    },
    computed: {
      state: function () {
        if (this.$route.query.pv == 1) {
          return true;
        } else {
          return false;
        }
      },
    },
    methods: {
      // 开关操作方法
      onChange(checked) {
      }
    },
};
</script>

<style scoped>
.tabletop {
  height: 50px;
  width: calc(100%-30px);
  background: #f3f3f5;
  line-height: 50px;
  font-size: 15px;
  padding-left: 30px;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 20px;
}
.switch {
  /* align-items: right; */
  float:right;
  margin-right: 20px;
}
</style>