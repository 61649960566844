<template>
  <div id="monitorInfo">
    <div class="monitorInfo-left">
      <div class="row">     
        <a-checkbox v-model= "showData.over" style="margin-right:16px" @change="StateChanges">  
        {{cbname}} 
        </a-checkbox>
        <a-input-number v-model= "showData.overCount" style="width: 80px" @change="StateChanges">             
        </a-input-number>        
        <span style="margin-right:16px">{{unit}}</span>
        <span>{{title}}</span>
        <a-input-number v-model= "showData.max" style="width: 80px" @change="StateChanges">        
        </a-input-number> 
        <span>{{unit}}</span>
      </div>      
    </div>    
  </div>
</template>

<script>
export default {
  //父窗口传入参数
  props: {
    cbname: String,
    unit: String,
    showData: {
      type:Object,
      default:function () {
        return {
          over: false,
          overCount: 0,
          max: 0,
        }
      }
    },
  },
  //本地参数
  data() {
    return {             
      title: "  "+this.$t('lightDetails.a14'),
    }
  },

  methods: {
    StateChanges() {
      this.$emit('transferData',this.form);//返回数据至父组件
    },
  },
};
</script>

<style scoped>
#monitorInfo {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*内边距*/
  padding: 20px 20px 20px 20px; 
  border-bottom: 1px solid #dedfdf;
  border-radius: 3px;
  /* 阴影 */
  /* box-shadow: 0px 0px 10px #bdbcbc; */ 
  /* margin-bottom: 20px; */
  overflow: hidden;
}
.baseinfo-left {
  display: flex;
}
p {
  margin-bottom: 0;
}
.row {
  margin-right: 40px;
}
.row span {
  font-size: 13px;
  color: #898989;
  white-space: nowrap;
}
.row p {
  font-size: 16px;
  white-space: nowrap;
}
.baseinfo-right {
  display: flex;
  align-items: center;
}
button {
  width: 120px;
  margin-left: 15px;
}
</style>