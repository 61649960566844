<!--维护监视（状态）-->
<template>
  <div id="monitorTable">
    <table>
      <tr class="row1">
        <td>
          <my-info 
            :cbname="cumulativeTime" 
            :unit = 'hour' 
            :showData="monitor.cumulativeTime"
            @transferData="transferCumulativeTime"
          ></my-info>
        </td>
      </tr>
      <tr class="row2">
        <td> 
          <my-info  
            :cbname="continuousTime" 
            :unit = 'hour' 
            :showData="monitor.continuousTime" 
            @transferData="transferContinuousTime"
          ></my-info>
        </td>
      </tr>
      <tr class="row3">
        <td>
          <my-info 
            :cbname="statusChangeTimes" 
            :unit = 'times' 
            :showData="monitor.statusChangeTimes"
            @transferData="transferStatusChangeTimes"
          ></my-info>
        </td>
      </tr>                 
    </table>
  </div>
</template>

<script>
import monitorInfo from "./monitorInfo";
export default {
  props: {
      monitor: {
        type:Object,
        default:function () {
          return {
            cumulativeTime:{},
            continuousTime:{},
            statusChangeTimes:{},
            alertTime:{},
            alertTimes:{},
          }
        }
      },
      itemIndex: String,   
  },
  data() {
    return {
       cumulativeTime: this.$t('lightDetails.b1'),
       continuousTime: this.$t('lightDetails.b2'),
       statusChangeTimes: this.$t('lightDetails.b3'),
       alertTime: this.$t('lightDetails.b4'),
       alertTimes: this.$t('lightDetails.b5'),
       hour:this.$t('lightDetails.b6'),
       times:this.$t('lightDetails.b7'),
       form:{
         cumulativeTime:{},
         continuousTime:{},
         statusChangeTimes:{},
         alertTime:{},
         alertTimes:{},
       }
    }
  },
  mounted(){
    console.log("子组件传参内容 monitor:",this.monitor);
  },
  methods: {
    transferCumulativeTime(msg){
      this.form.cumulativeTime=msg;
      this.$emit('transferData',this.monitor);//返回数据至父组件
    },
    transferContinuousTime(msg){
      this.form.continuousTime=msg;
      this.$emit('transferData',this.monitor);//返回数据至父组件
    },
    transferStatusChangeTimes(msg){
      this.form.statusChangeTimes=msg;
      this.$emit('transferData',this.monitor);//返回数据至父组件
    },
  },
  watch:{
    monitor: function (val) {   
      this.form.cumulativeTime = val.cumulativeTime;
      this.form.continuousTime = val.continuousTime;
      this.form.statusChangeTimes = val.statusChangeTimes;
      this.form.alertTime = val.alertTime;
      this.form.alertTimes = val.alertTimes;
    },
  },
  components: {
    "my-info": monitorInfo,
  }
};
</script>
<style scoped>
#monitorTable {
  width: 100%;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;
}
table {
  width: 100%;
  border-left: 1px solid #dedfdf;  
  border-right: 1px solid #dedfdf;
}
.tabletitle td {
  color: #3e3a39;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
}
.tablecontent td {
  padding: 10px 16px;
  font-size: 14px;
}
.top {
  border-top: 1px solid #dedfdf;
}
/* .table-button {
  width: 120px;
} */
img {
  width: 36px;
  margin-right: 15px;
}
</style>