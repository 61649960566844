<template>
  <div id="othertable">
    <table>
      <tr class="tabletitle" bgcolor="#f3f3f5">
        <td>
          <span>{{$t('lightDetails.c1')}}</span> 
        </td>
        <td >
          <span>{{$t('lightDetails.c2')}}</span> 
        </td>
        <td>
          <span>{{$t('lightDetails.c3')}}</span> 
        </td>
        <td >
          <span>{{$t('lightDetails.c4')}}</span> 
        </td>
        <td>
          <span>{{$t('lightDetails.c5')}}</span> 
        </td>
        <td >
          <span>{{$t('lightDetails.c6')}}</span> 
        </td>
        <td>
          <span>{{$t('lightDetails.c7')}}</span> 
        </td>
      </tr>
      <tr class="tablecontent" >
        <td>
          <span>{{rsName}}</span> 
        </td>
        <td >
          <span>{{objectId}}</span> 
        </td>
        <td>
          <span>{{tendency}}</span> 
        </td>
        <td >
          <span>{{unitClass}}</span> 
        </td>
        <td>
          <span>{{model}}</span> 
        </td>
        <td >
          <span>{{extend}}</span> 
        </td>
        <td>
          <span>{{terminalNo}}</span> 
        </td>
      </tr>               
    </table>
  </div>
</template>

<script>
import baseInfo from "./baseInfo";
export default {
  props: {
    remark: {
      type: Object,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    rsName:function(){
      if(this.remark!=null)
        return this.remark.rsName;
      else
        return "";
    },
    objectId:function(){
      if(this.remark!=null)
        return this.remark.objectId;
      else
        return "";
    },
    tendency:function(){
      if(this.remark!=null)
        return this.remark.tendency;
      else
        return "";
    },
    unitClass:function(){
      if(this.remark!=null)
        return this.remark.unitClass;
      else
        return "";
    },
    model:function(){
      if(this.remark!=null)
        return this.remark.model;
      else
        return "";
    },
    extend:function(){
      if(this.remark!=null)
        return this.remark.extend;
      else
        return "";
    },
    terminalNo:function(){
      if(this.remark!=null)
        return this.remark.terminalNo;
      else
        return "";
    },
  },
  components: {
    "my-info": baseInfo,
  }
};
</script>
<style scoped>
#othertable {
  width: 100%;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;
}
table {
  width: 100%;
  border-left: 1px solid #dedfdf;  
  border-right: 1px solid #dedfdf;
  border-bottom: 1px solid #dedfdf;
}
.tabletitle td {
  color: #3e3a39;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
}
.tablecontent td {
  padding: 10px 16px;
  font-size: 14px;
}
.top {
  border-top: 1px solid #dedfdf;
}
/* .table-button {
  width: 120px;
} */
img {
  width: 36px;
  margin-right: 15px;
}
</style>